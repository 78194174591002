import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Imprint() {
  const { t } = useTranslation()
  return (
    <Layout subTitle={t("Imprint")}>
      <section className="section is-small">
        <p>Created and maintained by:</p>
        <div
          className="badge-base LI-profile-badge"
          data-locale="en_US"
          data-size="medium"
          data-theme="light"
          data-type="VERTICAL"
          data-vanity="declanstobo"
          data-version="v1"
        >
          <a
            className="badge-base__link LI-simple-link"
            href="https://de.linkedin.com/in/declanstobo?trk=profile-badge"
          >
            Declan Stobo
          </a>
        </div>
      </section>
      <section className="section is-small">
        <h2 className="title is-2 is-spaced">Impressum</h2>
        <p>Freundeskreis Nürnberg-Glasgow e.V.</p>
        <p>Häherweg 11 </p>
        <p>90480 Nürnberg</p>
        <p>Telefon: 0171 2630091</p>
        <p>E-Mail: </p>
        <p>
          <a href="mailto:webmaster@freundeskreis-nuernberg-glasgow.com">
            webmaster@freundeskreis-nuernberg-glasgow.com
          </a>
        </p>
      </section>
      <section className="section">
        <h3 className="subtitle is-3">Vertreten durch:</h3>
        <div>
          <p>Malte Denker</p>
          <p>Dr. Hans-Dieter Metzger</p>
          <p>Jochen Sand</p>
          <p>Marion Schück</p>
          <p>Barbara Denker</p>
        </div>
      </section>
      <section className="section">
        <h3 className="subtitle is-3">Registereintrag:</h3>
        <div>
          <p>Eingetragen im Vereinsregister.</p>
          <p>Registergericht: Nürnberg</p>
          <p>Registernummer: VR 3656</p>
        </div>
      </section>
      <section className="section">
        <h3 className="subtitle is-3">
          Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV):
        </h3>

        <div>
          <p>Malte Denker</p>
          <p>Dr. Hans-Dieter Metzger</p>
          <p>Jochen Sand</p>
          <p>Marion Schück</p>
          <p>Barbara Denker</p>
        </div>
      </section>
      <section className="section">
        <div className="content">
          <h2>Hinweis gem&auml;&szlig; Online-Streitbeilegungs-Verordnung</h2>
          <p>
            Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die
            Existenz der Europ&auml;ischen Online-Streitbeilegungs-Plattform
            hinzuweisen, die f&uuml;r die Beilegung von Streitigkeiten genutzt
            werden kann, ohne dass ein Gericht eingeschaltet werden muss.
            F&uuml;r die Einrichtung der Plattform ist die Europ&auml;ische
            Kommission zust&auml;ndig. Die Europ&auml;ische
            Online-Streitbeilegungs-Plattform ist hier zu finden:{" "}
            <a href="http://ec.europa.eu/odr" target="_blank" rel="noreferrer">
              http://ec.europa.eu/odr
            </a>
            . Unsere E-Mail lautet:{" "}
            <a href="mailto:webmaster@freundeskreis-nuernberg-glasgow.com">
              webmaster@freundeskreis-nuernberg-glasgow.com
            </a>
          </p>
          <p>
            Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am
            Streitbeilegungsverfahren im Rahmen der Europ&auml;ischen
            Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
            Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
          </p>
          <br />
          <h2>
            Hinweis gem&auml;&szlig; Verbraucherstreitbeilegungsgesetz (VSBG)
          </h2>
          <p>
            Wir sind nicht bereit und verpflichtet, an Streitbeilegungsverfahren
            vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
          <br />
          <br />
          <h2>Disclaimer - rechtliche Hinweise</h2>
          § 1 Warnhinweis zu Inhalten
          <br />
          Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden
          mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite
          übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der
          bereitgestellten kostenlosen und frei zugänglichen journalistischen
          Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben
          die Meinung des jeweiligen Autors und nicht immer die Meinung des
          Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei
          zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem
          Nutzer und dem Anbieter zustande, insoweit fehlt es am
          Rechtsbindungswillen des Anbieters.
          <br />
          <br />
          § 2 Externe Links
          <br />
          Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
          Links"). Diese Websites unterliegen der Haftung der jeweiligen
          Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
          externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
          Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
          ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und
          zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das
          Setzen von externen Links bedeutet nicht, dass sich der Anbieter die
          hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
          ständige Kontrolle der externen Links ist für den Anbieter ohne
          konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von
          Rechtsverstößen werden jedoch derartige externe Links unverzüglich
          gelöscht.
          <br />
          <br />
          § 3 Urheber- und Leistungsschutzrechte
          <br />
          Die auf dieser Website veröffentlichten Inhalte unterliegen dem
          deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
          Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf
          der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
          Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
          Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
          von Inhalten in Datenbanken oder anderen elektronischen Medien und
          Systemen. Inhalte und Rechte Dritter sind dabei als solche
          gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
          einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
          strafbar. Lediglich die Herstellung von Kopien und Downloads für den
          persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
          <br />
          <br />
          Die Darstellung dieser Website in fremden Frames ist nur mit
          schriftlicher Erlaubnis zulässig.
          <br />
          <br />
          § 4 Besondere Nutzungsbedingungen
          <br />
          Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
          den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
          ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
          Einzelfall die besonderen Nutzungsbedingungen.
        </div>
      </section>
      <section className="section">
        <p>
          <strong>Quelle:</strong>
        </p>
        <a href="https://www.juraforum.de/impressum-generator/">
          Artikel aufrufen
        </a>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
